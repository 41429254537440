import React from 'react';

const Header = () => {
    return (
        <div className="header">
            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3">
                        <span className="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div className="site-mobile-menu-body"></div>
            </div>
            <header className="site-navbar js-sticky-header site-navbar-target" role="banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 col-xl-2">
                            <h1 className="mb-0 site-logo"><a href="#home-section" className="h2 mb-0">Вільному Воля</a>
                            </h1>
                        </div>
                        <div className="col-12 col-md-10 d-none d-xl-block">
                            <nav className="site-navigation position-relative text-right" role="navigation">
                                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                    <li><a href="#home-section" className="nav-link">Головна</a></li>
                                    <li><a href="#services-section" className="nav-link">Про нас</a></li>
                                    <li><a href="#gallery-section" className="nav-link">Галерея</a></li>
                                    <li><a href="#blog-section" className="nav-link">Блог</a></li>
                                    <li><a href="#contact-section" className="nav-link">Контакти</a></li>
                                    <li className="social"><a href="https://www.facebook.com/vvolyaif/" className="nav-link" target="_blank"><span
                                        className="icon-facebook"></span></a></li>
                                    <li className="social"><a href="https://www.facebook.com/vvolyaif/" className="nav-link" target="_blank"><span
                                        className="icon-twitter"></span></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3 menu_additional"><a href="/"
                                                                      className="site-menu-toggle js-menu-toggle float-right"><span
                            className="icon-menu h3"></span></a></div>
                    </div>
                </div>
            </header>
            <div className="site-blocks-cover overlay" data-aos="fade"
                 id="home-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10 mt-lg-5 text-center">
                            <div className="single-text owl-carousel">
                                <div className="slide">
                                    <h1 className="text-uppercase" data-aos="fade-up">Вільному Воля</h1>
                                    <p className="mb-5 desc" data-aos="fade-up" data-aos-delay="100">На рідній землі!</p>
                                    <div data-aos="fade-up" data-aos-delay="100">
                                        <a href="#contact-section" target="_blank"
                                           className="btn  btn-primary mr-2 mb-2 scrollEnter">Вступити</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="mouse smoothscroll">
                    <span className="mouse-icon">
                      <span className="mouse-wheel"></span>
                    </span>
                </a>
            </div>
        </div>
    );
}

export default Header;
