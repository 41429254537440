import {errorsResult, isRequire, isEmail, startValidation} from "../helpers/Validation";
import * as types from "../types/footerType";
import axios from "axios";


export const sendSignForm = (email) =>async dispatch=>{
    const params = new URLSearchParams();
    params.append('email', email);

    axios({
         method: 'post',
         url: 'https://admin.vilnomu-volya.in.ua/api/subscriber',
         data: params
    }).then(function (response) {
         if (response.data.message === 'Success') {
             dispatch({
                 type: types.FOOTER_SUCCESS,
                message: 'Ви успішно підписались на новини!',
             });
         }
     }).catch(function (error) {
         const errorResponse = (error.response.data.message) ? error.response.data.message : error.response.data.error;
         dispatch({
             type: types.FOOTER_ERROR,
             errors: [errorResponse],
         });
     });
 }

export const emailValidate = (value) =>async dispatch=>{
    const field = 'email';

    startValidation(field);
    isRequire(value, field);
    isEmail(value, field);

    dispatch({
        type: types.FOOTER_SET_EMAIL_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.FOOTER_CHANGED_EMAIL_STATE,
        changed: true,
    });
};
