import {combineReducers} from "redux";
import {contactReducer} from "./reducers/contactReducer";
import {footerReducer} from "./reducers/footerReducer";
import {blogReducer} from "./reducers/blogReducer";


export default combineReducers({
    contact: contactReducer,
    footer: footerReducer,
    blog: blogReducer,
});
