import React from 'react';
import {useSelector} from "react-redux";


const Gallery = () => {
    const {gallery} = useSelector(state => state.blog);

    return (
        <section className="site-section" id="gallery-section" data-aos="fade">
            <div className="container">
                <div className="row mb-3">
                    <div className="col-12 text-center">
                        <h2 className="section-title mb-3">Галерея</h2>
                    </div>
                </div>
                <div id="posts" className="row no-gutter gallery-posts">
                    {gallery && gallery.map(item => (
                        <div key={item.url} className="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                            <a href={item.url} className="item-wrap fancybox" data-fancybox="gallery2">
                                <span className="icon-search2"></span>
                                <img className="img-fluid gallery-img" src={item.url} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Gallery;
