import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as types from '../../redux/types/contactType';
import {
    firstnameValidate,
    lastnameValidate,
    surnameValidate,
    birthdayValidate,
    bornplaceValidate,
    liveplaceValidate,
    workValidate,
    phoneValidate,
    serveValidate, sendContactForm
} from "../../redux/actions/contactAction";
import ContactInput from "./ContactInput";

const Contact = () => {

    const dispatch = useDispatch();
    const {
        lastname, errorsLastname, changedLastname,
        firstname, errorsFirstname, changedFirstname,
        surname, errorsSurname, changedSurname,
        birthday, errorsBirthday, changedBirthday,
        bornplace, errorsBornplace, changedBornplace,
        liveplace, errorsLiveplace, changedLiveplace,
        work, errorsWork, changedWork,
        phone, errorsPhone, changedPhone,
        serve, errorsServe, changedServe,
        other, success_message, error_messages
    } = useSelector(state => state.contact);

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(sendContactForm(lastname, firstname, surname, birthday, bornplace, liveplace, work, phone, serve, other));
        dispatch({
            type: types.CONTACT_RESET_FORM
        });
    };

    const isValid = () => {
        if (
            changedLastname && changedFirstname && changedSurname && changedBirthday
            && changedBornplace && changedLiveplace && changedWork && changedPhone
            && changedServe &&
            errorsLastname.length === 0
            && errorsFirstname.length === 0
            && errorsSurname.length === 0
            && errorsBirthday.length === 0
            && errorsBornplace.length === 0
            && errorsLiveplace.length === 0
            && errorsWork.length === 0
            && errorsPhone.length === 0
            && errorsServe.length === 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    const onChangeLastName = (event) => {
        dispatch({type: types.CONTACT_CHANGE_LASTNAME, lastname: event.target.value});
        dispatch(lastnameValidate(event.target.value));
    };

    const onChangeFirstName = (event) => {
        dispatch({type: types.CONTACT_CHANGE_FIRSTNAME, firstname: event.target.value});
        dispatch(firstnameValidate(event.target.value));
    };

    const onChangeSurName = (event) => {
        dispatch({type: types.CONTACT_CHANGE_SURNAME, surname: event.target.value});
        dispatch(surnameValidate(event.target.value));
    };

    const onChangeBirthday = (event) => {
        dispatch({type: types.CONTACT_CHANGE_BIRTHDAY, birthday: event.target.value});
        dispatch(birthdayValidate(event.target.value));
    };

    const onChangeBornPlace = (event) => {
        dispatch({type: types.CONTACT_CHANGE_BORNPLACE, bornplace: event.target.value});
        dispatch(bornplaceValidate(event.target.value));
    };

    const onChangeLivePlace = (event) => {
        dispatch({type: types.CONTACT_CHANGE_LIVEPLACE, liveplace: event.target.value});
        dispatch(liveplaceValidate(event.target.value));
    };

    const onChangeWork = (event) => {
        dispatch({type: types.CONTACT_CHANGE_WORK, work: event.target.value});
        dispatch(workValidate(event.target.value));
    };

    const onChangePhone = (event) => {
        dispatch({type: types.CONTACT_CHANGE_PHONE, phone: event.target.value});
        dispatch(phoneValidate(event.target.value));
    };

    const onChangeServe = (event) => {
        dispatch({type: types.CONTACT_CHANGE_SERVE, serve: event.target.value});
        dispatch(serveValidate(event.target.value));
    };

    const onChangeOther = (event) => {
        dispatch({type: types.CONTACT_CHANGE_OTHER, other: event.target.value});
    };

    return (
            <section className="site-section bg-light" id="contact-section" data-aos="fade">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <h2 className="section-title mb-3">Контакти</h2>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-4 text-center">
                            <p className="mb-4">
                                <span className="icon-room d-block h2 text-primary"></span>
                                <span>Івано-Франківськ, Україна</span>
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <p className="mb-4">
                                <span className="icon-phone d-block h2 text-primary"></span>
                                <a href="/">+38 050 845 4888</a>
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <p className="mb-0">
                                <span className="icon-mail_outline d-block h2 text-primary"></span>
                                <a href= "mailto:vilnuy2016@gmail.com">vilnuy2016@gmail.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <form type="POST" onSubmit={onSubmit} action="#" className="p-5 bg-white">
                                <h2 className="h4 text-black mb-5">Вступити до нас</h2>
                                <div className="row form-group">
                                    <div className="col-md-4 mb-3 mb-md-0">
                                        <ContactInput
                                            value={lastname}
                                            label={'Прізвище'}
                                            name={'lastname'}
                                            errors={errorsLastname}
                                            onChange={onChangeLastName}
                                            onFocus={onChangeLastName}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <ContactInput
                                            value={firstname}
                                            label={'Ім\'я'}
                                            name={'firstname'}
                                            errors={errorsFirstname}
                                            onChange={onChangeFirstName}
                                            onFocus={onChangeFirstName}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <ContactInput
                                            value={surname}
                                            label={'По-батькові'}
                                            name={'surname'}
                                            errors={errorsSurname}
                                            onChange={onChangeSurName}
                                            onFocus={onChangeSurName}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-6">
                                        <ContactInput
                                            value={birthday}
                                            label={'Дата Народження'}
                                            name={'birthday'}
                                            errors={errorsBirthday}
                                            onChange={onChangeBirthday}
                                            onFocus={onChangeBirthday}
                                            type={'date'}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ContactInput
                                            value={bornplace}
                                            label={'Місце народження'}
                                            name={'bornplace'}
                                            errors={errorsBornplace}
                                            onChange={onChangeBornPlace}
                                            onFocus={onChangeBornPlace}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <ContactInput
                                            value={liveplace}
                                            label={'Теперішнє місце проживання'}
                                            name={'liveplace'}
                                            errors={errorsLiveplace}
                                            onChange={onChangeLivePlace}
                                            onFocus={onChangeLivePlace}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <ContactInput
                                            value={work}
                                            label={'Робота'}
                                            name={'work'}
                                            errors={errorsWork}
                                            onChange={onChangeWork}
                                            onFocus={onChangeWork}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <ContactInput
                                            value={phone}
                                            label={'Телефон'}
                                            name={'phone'}
                                            errors={errorsPhone}
                                            onChange={onChangePhone}
                                            onFocus={onChangePhone}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <ContactInput
                                            value={serve}
                                            label={'Військовий служба'}
                                            name={'serve'}
                                            errors={errorsServe}
                                            onChange={onChangeServe}
                                            onFocus={onChangeServe}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="other">Інша інформація</label>
                                        <textarea
                                            value={other}
                                            onChange={onChangeOther}
                                            name="other"
                                            id="other"
                                            cols="30"
                                            rows="7"
                                            className="form-control"
                                            placeholder="Інша інформація"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <input type="submit" value="Відправити" disabled={!isValid()}
                                               className="btn btn-primary btn-md text-white" />
                                    </div>
                                </div>
                                <div className="error_block">
                                    {error_messages.length > 0 ? (
                                        <div className="alert alert-danger" role="alert">
                                            {error_messages && error_messages.map(item => (
                                                <p key={item}>{item}</p>
                                            ))}
                                        </div>
                                    ) : null}
                                    {success_message ? (
                                        <div className="success-message-big">{success_message}</div>
                                    ): null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Contact;
