import {errorsResult, isRequire, maxLength, minLength, startValidation, isPhone} from "../helpers/Validation";
import * as types from "../types/contactType";
import axios from "axios";

export const sendContactForm = (lastname, firstname, surname, birthday, bornplace, liveplace, work, phone, serve, other) =>async dispatch=>{
    const params = new URLSearchParams();
    params.append('firstname', firstname);
    params.append('lastname', lastname);
    params.append('surname', surname);
    params.append('birthday_date', birthday);
    params.append('birthday_place', bornplace);
    params.append('living_place', liveplace);
    params.append('profession', work);
    params.append('phone', phone);
    params.append('serve', serve);
    params.append('other', other);

    axios({
        method: 'post',
        url: 'https://admin.vilnomu-volya.in.ua/api/members',
        data: params
    }).then(function (response) {
        if (response.data.message === 'Success') {
            dispatch({
                type: types.CONTACT_SUCCESS,
                message: 'Ви успішно надіслали форму. Дякуємо за заявку!',
            });
        }
    }).catch(function (error) {
        const errorResponse = (error.response.data.message) ? error.response.data.message : error.response.data.error;
        dispatch({
            type: types.CONTACT_ERROR,
            errors: [errorResponse],
        });
    });
}


export const lastnameValidate = (value) =>async dispatch=>{
    const field = 'Прізвище';

    startValidation(field);
    isRequire(value, field);
    minLength(value, field, 2);
    maxLength(value, field, 50);

    dispatch({
        type: types.CONTACT_SET_LASTNAME_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_LASTNAME_STATE,
        changed: true,
    });
};

export const firstnameValidate = (value) =>async dispatch=>{
    const field = 'Ім\'я';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_FIRSTNAME_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_FIRSTNAME_STATE,
        changed: true,
    });
};

export const surnameValidate = (value) =>async dispatch=>{
    const field = 'По-батькові';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_SURNAME_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_SURNAME_STATE,
        changed: true,
    });
};

export const birthdayValidate = (value) =>async dispatch=>{
    const field = 'Дата народження';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_BIRTHDAY_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_BIRTHDAY_STATE,
        changed: true,
    });
};

export const bornplaceValidate = (value) =>async dispatch=>{
    const field = 'Місце народження';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_BORNPLACE_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_BORNPLACE_STATE,
        changed: true,
    });
};

export const liveplaceValidate = (value) =>async dispatch=>{
    const field = 'Теперішнє місце проживання';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_LIVEPLACE_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_LIVEPLACE_STATE,
        changed: true,
    });
};

export const workValidate = (value) =>async dispatch=>{
    const field = 'Робота';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_WORK_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_WORK_STATE,
        changed: true,
    });
};

export const phoneValidate = (value) =>async dispatch=>{
    const field = 'Телефон';

    startValidation(field);
    isRequire(value, field);
    isPhone(value, field);

    dispatch({
        type: types.CONTACT_SET_PHONE_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_PHONE_STATE,
        changed: true,
    });
};

export const serveValidate = (value) =>async dispatch=>{
    const field = 'Військова служба';

    startValidation(field);
    isRequire(value, field);

    dispatch({
        type: types.CONTACT_SET_SERVE_ERROR,
        errors: errorsResult[field],
    });
    dispatch({
        type: types.CONTACT_CHANGED_SERVE_STATE,
        changed: true,
    });
};
