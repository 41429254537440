import * as types from "../types/blogType";

const initialState = {
    posts: [],
    gallery: [],
};

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.BLOG_SET_POSTS:
            return {
                ...state,
                posts: action.posts,
            };
        case types.BLOG_SET_GALLERY:
            return {
                ...state,
                gallery: action.gallery,
            };
        default:
            return state;
    }
}
