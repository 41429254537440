import React from 'react';

const ContactInput = (props) => {
    return (
        <div>
            <label className="text-black" htmlFor="lastname">{props.label}</label>
            <input
                type={props.type ? props.type : 'text'}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
                name={props.name}
                id={props.name}
                className={props.errors && props.errors.length > 0 ? 'form-control input-error' : 'form-control'}
            />
            {props.errors && props.errors.length > 0 ? (
                <div>
                    {props.errors.map(item => (
                        <div className="error-message" key={item}>{item}</div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

export default ContactInput;
