export const CONTACT_CHANGE_LASTNAME = 'CONTACT_CHANGE_LASTNAME';
export const CONTACT_SET_LASTNAME_ERROR = 'CONTACT_SET_LASTNAME_ERROR';
export const CONTACT_CHANGED_LASTNAME_STATE = 'CONTACT_CHANGED_LASTNAME_STATE';

export const CONTACT_CHANGE_FIRSTNAME = 'CONTACT_CHANGE_FIRSTNAME';
export const CONTACT_SET_FIRSTNAME_ERROR = 'CONTACT_SET_FIRSTNAME_ERROR';
export const CONTACT_CHANGED_FIRSTNAME_STATE = 'CONTACT_CHANGED_FIRSTNAME_STATE';

export const CONTACT_CHANGE_SURNAME = 'CONTACT_CHANGE_SURNAME';
export const CONTACT_SET_SURNAME_ERROR = 'CONTACT_SET_SURNAME_ERROR';
export const CONTACT_CHANGED_SURNAME_STATE = 'CONTACT_CHANGED_SURNAME_STATE';

export const CONTACT_CHANGE_BIRTHDAY = 'CONTACT_CHANGE_BIRTHDAY';
export const CONTACT_SET_BIRTHDAY_ERROR = 'CONTACT_SET_BIRTHDAY_ERROR';
export const CONTACT_CHANGED_BIRTHDAY_STATE = 'CONTACT_CHANGED_BIRTHDAY_STATE';

export const CONTACT_CHANGE_BORNPLACE = 'CONTACT_CHANGE_BORNPLACE';
export const CONTACT_SET_BORNPLACE_ERROR = 'CONTACT_SET_BORNPLACE_ERROR';
export const CONTACT_CHANGED_BORNPLACE_STATE = 'CONTACT_CHANGED_BORNPLACE_STATE';

export const CONTACT_CHANGE_LIVEPLACE = 'CONTACT_CHANGE_LIVEPLACE';
export const CONTACT_SET_LIVEPLACE_ERROR = 'CONTACT_SET_LIVEPLACE_ERROR';
export const CONTACT_CHANGED_LIVEPLACE_STATE = 'CONTACT_CHANGED_LIVEPLACE_STATE';

export const CONTACT_CHANGE_WORK = 'CONTACT_CHANGE_WORK';
export const CONTACT_SET_WORK_ERROR = 'CONTACT_SET_WORK_ERROR';
export const CONTACT_CHANGED_WORK_STATE = 'CONTACT_CHANGED_WORK_STATE';

export const CONTACT_CHANGE_PHONE = 'CONTACT_CHANGE_PHONE';
export const CONTACT_SET_PHONE_ERROR = 'CONTACT_SET_PHONE_ERROR';
export const CONTACT_CHANGED_PHONE_STATE = 'CONTACT_CHANGED_PHONE_STATE';

export const CONTACT_CHANGE_SERVE = 'CONTACT_CHANGE_SERVE';
export const CONTACT_SET_SERVE_ERROR = 'CONTACT_SET_SERVE_ERROR';
export const CONTACT_CHANGED_SERVE_STATE = 'CONTACT_CHANGED_SERVE_STATE';

export const CONTACT_CHANGE_OTHER = 'CONTACT_CHANGE_OTHER';
export const CONTACT_SET_OTHER_ERROR = 'CONTACT_SET_OTHER_ERROR';
export const CONTACT_CHANGED_OTHER_STATE = 'CONTACT_CHANGED_OTHER_STATE';

export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const CONTACT_RESET_FORM = 'AUTH_RESET_FORM';
