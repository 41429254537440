import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getBlog} from "../redux/actions/blogAction";

const Blog = () => {

    const dispatch = useDispatch();
    const {posts} = useSelector(state => state.blog);

    useEffect(() => {
        dispatch(getBlog());
    }, []);

    const formatDate = (fullDate) => {
        return fullDate.substring(0, 10);
    }

    const resizeText = (text) => {
        let string = text + ' ';
        return string.split(/\s+/).slice(0,10).join(" ") + ' ...';
    }

    return (
            <section className="site-section" id="blog-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-title mb-3">Останні новини з блогу</h2>
                        </div>
                    </div>
                    <div className="row">
                        {posts && posts.map(item => (
                        <div key={item.id} className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="h-entry">
                                <a href={item.permalink_url} target="_blank" rel="noopener noreferrer">
                                    <img src={item.full_picture} alt="" className="img-fluid blog_img" />
                                </a>
                                <h2 className="font-size-regular"><a href={item.permalink_url}>{resizeText(item.message)}</a></h2>
                                <div className="meta mb-4">{formatDate(item.created_time)}</div>
                                <p><a href={item.permalink_url} target="_blank" rel="noopener noreferrer">Читати повністю <span className="icon-arrow-right"></span></a></p>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
    );
}

export default Blog;
