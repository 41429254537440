export const errorsResult = [];

export const startValidation = (field) => {
    errorsResult[field] = [];
}

export const isEmail = (value, field) => {
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(value)) {
        errorsResult[field].push(`Поле ${field} не є валідним email адресом.`);
    }
}

export const isRequire = (value, field) => {
    if (value === "" || !value) {
        errorsResult[field].push(`Поле ${field} не повинно бути порожнє.`);
    }
}

export const isPhone = (value, field) => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(value)) {
        errorsResult[field].push(`Поле ${field} повинно бути валідним номером телефону (0970000000).`);
    }
}

export const minLength = (value, field, count) => {
    if (value.length < count) {
        errorsResult[field].push(`Поле ${field} не повинно бути менше ніж ${count} символів.`);
    }
}

export const maxLength = (value, field, count) => {
    if (value.length > count) {
        errorsResult[field].push(`The field ${field} не повинно бути більше ніж ${count} символів.`);
    }
}
