import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as types from "../redux/types/footerType";
import {emailValidate, sendSignForm} from "../redux/actions/footerAction";


const Footer = () => {

    const dispatch = useDispatch();
    const {
        email, errorsEmail, changedEmail, success_message, error_messages
    } = useSelector(state => state.footer);

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(sendSignForm(email));
        dispatch({
            type: types.FOOTER_RESET_FORM
        });
    };

    const isValid = () => {
        if (changedEmail && errorsEmail.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const onChangeEmail = (event) => {
        dispatch({type: types.FOOTER_CHANGE_EMAIL, email: event.target.value});
        dispatch(emailValidate(event.target.value));
    };

    return (
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-5">
                                    <h2 className="footer-heading mb-4">Про нас</h2>
                                    <p>Громадську організацію учасників АТО "Вільному Воля" було засновано в грудні 2015
                                        року.
                                        Всі учасники організації це - хлопці з багатим воєнним досвідом, які брали
                                        участь у війні на Сході України.</p>
                                </div>
                                <div className="col-md-3 offset-md-1 footer-social">
                                    <h2 className="footer-heading mb-4">Соцмережі</h2>
                                    <a href="https://www.facebook.com/vvolyaif/" target="_blank" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
                                    <a href="https://www.facebook.com/vvolyaif/" target="_blank" className="pl-3 pr-3"><span className="icon-twitter"></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <h2 className="footer-heading mb-4">Підписатись на новини</h2>
                            <form type="POST" onSubmit={onSubmit} className="footer-subscribe">
                                <div className="input-group mb-3">
                                    <input type="text"
                                           className="form-control border-secondary text-white bg-transparent"
                                           placeholder="Введіть Email" aria-label="Enter Email"
                                           aria-describedby="button-addon2"
                                           value={email}
                                           name='email'
                                           onChange={onChangeEmail}
                                           onFocus={onChangeEmail}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary text-black" type="submit" id="button-addon2" disabled={!isValid()}>Підписатись</button>
                                    </div>
                                    {errorsEmail && errorsEmail.length > 0 ? (
                                        <div className="mt-1">
                                            {errorsEmail.map(item => (
                                                <div className="error-message" key={item}>{item}</div>
                                            ))}
                                        </div>
                                    ) : null}
                                    <div className="error_block mt-1">
                                        {error_messages.length > 0 ? (
                                            <div className="mt-1">
                                                {error_messages && error_messages.map(item => (
                                                    <div className="error-message" key={item}>{item}</div>
                                                ))}
                                            </div>
                                        ) : null}
                                        {success_message ? (
                                            <div className="success-message">{success_message}</div>
                                        ): null}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row pt-5 mt-5 text-center">
                        <div className="col-md-12">
                            <div className="border-top pt-5">
                                <p>Copyright &copy;
                                    <script>document.write(new Date().getFullYear());</script>
                                    Усі права захищені. | Розроблено
                                    <i className="icon-heart-o" aria-hidden="true"></i> <a href="/" target="_blank">VV
                                        Team</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
}

export default Footer;
