import React from 'react';

const About = () => {
    return (
            <section className="site-section border-bottom bg-light" id="services-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-title mb-3">Про нас</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                            <div className="unit-4">
                                <div>
                                    <h3>Історія</h3>
                                    <p>Громадську організацію учасників АТО "Вільному Воля" було засновано в грудні 2015 року.
                                        Організацію очолює учасник АТО головний сержант 80 ДШБ Ігор Синяк.
                                        Всі учасники організації це - хлопці з багатим воєнним досвідом, які брали участь у війні на Сході України.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="unit-4">
                                <div>
                                    <h3>Сьогодення</h3>
                                    <p>Організація активно набирає обертів. З кожним днем кількість членів організації невпинно зростає.
                                        Це дає нам можливість розширювати поле своєї діяльності.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="unit-4">
                                <div>
                                    <h3>Право членства</h3>
                                    <p>Питання вступу в організацію вирішується на загальних зборах шляхом відкритого голосування.
                                        Переважне право на вступ в організацію мають учасники АТО.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="">
                            <div className="unit-4">
                                <div>
                                    <h3>Наші цілі</h3>
                                    <p>Протидія антиукраїнським та сепаратистським проявам, забезпечення обов’язкового соціального пакета учасників АТО, членів їх сімей,
                                        реабілітація, адаптація та лікування поранених бійців, забезпечення охорони життя і здоров'я членів організації та їх сімей.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="unit-4">
                                <div>
                                    <h3>Фінансування організації</h3>
                                    <p>Ми не співпрацюємо з жодною політичною партією. З олігархами не пов’язані.
                                        Організація фінансується за допомогою добровільних внесків .</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="unit-4">
                                <div>
                                    <h3>Співпраця</h3>
                                    <p>Ми готові до всебічної співпраці з однодумцями на засадах рівності, взаємоповаги та взаємопідтримки у досягненні спільних цілей.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;