import axios from "axios";
import * as types from "../types/blogType";

export const getBlog = () =>async dispatch=>{
    const token = 'EAAMRJO0WnkIBAJIAecSZAlqvy2vO0MvRXm0Kqknh8rvsDxWQcZBh24KrZANcOcZBm03pk7bT8CTzLKBvEC6820QIA19nVUKcZBKP7Qwno6uZAYE' +
        'LceZCmZBvjUQZBCChKH9SnnqN11Pe3d5GDSe9weUdZC8wFh3jJYvy8tHcByxSZCIkbDWOxQG34FG';
    axios({
        method: 'get',
        url: 'https://graph.facebook.com/v11.0/455961237934938/posts?fields=created_time,full_picture,message,permalink_url,shares,attachments',
        headers: {
            'get': {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    }).then(function (response) {
        const filteredPosts = filterPosts(response.data.data);
        let posts = filteredPosts.slice(0, 3);
        let gallery = filterGallery(filteredPosts).slice(0, 12);
        dispatch({
            type: types.BLOG_SET_POSTS,
            posts: posts,
        });
        dispatch({
            type: types.BLOG_SET_GALLERY,
            gallery: gallery,
        });
    }).catch(function (error) {
        console.log(error);
    });
}

const filterPosts = (posts) => {
    const filteredPosts = posts.filter((post) => {
        if (post.message && post.permalink_url && post.full_picture && post.message !== '' && post.permalink_url !== '' && post.full_picture !== '') {
            return true;
        } else {
            return false;
        }
    })
    return filteredPosts;
}

const filterGallery = (posts) => {
    const filteredGallery = [];
    posts.forEach((post) => {
        if (post.attachments.data[0].subattachments) {
            const subItems = post.attachments.data[0].subattachments.data;
            subItems.forEach((item, key) => {
                if (key !== 0) {
                    filteredGallery.push({url: item.media.image.src});
                }
            });
        }
    })
    return filteredGallery;
}
