import * as types from "../types/contactType";

const initialState = {
    lastname: '',
    errorsLastname: [],
    changedLastname: false,

    firstname: '',
    errorsFirstname: [],
    changedFirstname: false,

    surname: '',
    errorsSurname: [],
    changedSurname: false,

    birthday: '',
    errorsBirthday: [],
    changedBirthday: false,

    bornplace: '',
    errorsBornplace: [],
    changedBornplace: false,

    liveplace: '',
    errorsLiveplace: [],
    changedLiveplace: false,

    work: '',
    errorsWork: [],
    changedWork: false,

    phone: '',
    errorsPhone: [],
    changedPhone: false,

    serve: '',
    errorsServe: [],
    changedServe: false,

    other: '',
    errorsOther: [],
    changedOther: false,

    success_message: '',
    error_messages: [],
};


export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_ERROR:
            return {
                ...state,
                success_message: '',
                error_messages: action.errors
            };
        case types.CONTACT_SUCCESS:
            return {
                ...state,
                success_message: action.message,
                error_messages: []
            };

        case types.CONTACT_CHANGE_LASTNAME:
            return {
                ...state,
                success_message: '',
                lastname: action.lastname
            };
        case types.CONTACT_SET_LASTNAME_ERROR:
            return {
                ...state,
                errorsLastname: action.errors
            };
        case types.CONTACT_CHANGED_LASTNAME_STATE:
            return {
                ...state,
                changedLastname: action.changed
            };

        case types.CONTACT_CHANGE_FIRSTNAME:
            return {
                ...state,
                success_message: '',
                firstname: action.firstname
            };
        case types.CONTACT_SET_FIRSTNAME_ERROR:
            return {
                ...state,
                errorsFirstname: action.errors
            };
        case types.CONTACT_CHANGED_FIRSTNAME_STATE:
            return {
                ...state,
                changedFirstname: action.changed
            };

        case types.CONTACT_CHANGE_SURNAME:
            return {
                ...state,
                success_message: '',
                surname: action.surname
            };
        case types.CONTACT_SET_SURNAME_ERROR:
            return {
                ...state,
                errorsSurname: action.errors
            };
        case types.CONTACT_CHANGED_SURNAME_STATE:
            return {
                ...state,
                changedSurname: action.changed
            };

        case types.CONTACT_CHANGE_BIRTHDAY:
            return {
                ...state,
                success_message: '',
                birthday: action.birthday
            };
        case types.CONTACT_SET_BIRTHDAY_ERROR:
            return {
                ...state,
                errorsBirthday: action.errors
            };
        case types.CONTACT_CHANGED_BIRTHDAY_STATE:
            return {
                ...state,
                changedBirthday: action.changed
            };

        case types.CONTACT_CHANGE_BORNPLACE:
            return {
                ...state,
                success_message: '',
                bornplace: action.bornplace
            };
        case types.CONTACT_SET_BORNPLACE_ERROR:
            return {
                ...state,
                errorsBornplace: action.errors
            };
        case types.CONTACT_CHANGED_BORNPLACE_STATE:
            return {
                ...state,
                changedBornplace: action.changed
            };

        case types.CONTACT_CHANGE_LIVEPLACE:
            return {
                ...state,
                success_message: '',
                liveplace: action.liveplace
            };
        case types.CONTACT_SET_LIVEPLACE_ERROR:
            return {
                ...state,
                errorsLiveplace: action.errors
            };
        case types.CONTACT_CHANGED_LIVEPLACE_STATE:
            return {
                ...state,
                changedLiveplace: action.changed
            };

        case types.CONTACT_CHANGE_WORK:
            return {
                ...state,
                success_message: '',
                work: action.work
            };
        case types.CONTACT_SET_WORK_ERROR:
            return {
                ...state,
                errorsWork: action.errors
            };
        case types.CONTACT_CHANGED_WORK_STATE:
            return {
                ...state,
                changedWork: action.changed
            };

        case types.CONTACT_CHANGE_PHONE:
            return {
                ...state,
                success_message: '',
                phone: action.phone
            };
        case types.CONTACT_SET_PHONE_ERROR:
            return {
                ...state,
                errorsPhone: action.errors
            };
        case types.CONTACT_CHANGED_PHONE_STATE:
            return {
                ...state,
                changedPhone: action.changed
            };

        case types.CONTACT_CHANGE_SERVE:
            return {
                ...state,
                success_message: '',
                serve: action.serve
            };
        case types.CONTACT_SET_SERVE_ERROR:
            return {
                ...state,
                errorsServe: action.errors
            };
        case types.CONTACT_CHANGED_SERVE_STATE:
            return {
                ...state,
                changedServe: action.changed
            };

        case types.CONTACT_CHANGE_OTHER:
            return {
                ...state,
                success_message: '',
                other: action.other
            };
        case types.CONTACT_SET_OTHER_ERROR:
            return {
                ...state,
                errorsOther: action.errors
            };
        case types.CONTACT_CHANGED_OTHER_STATE:
            return {
                ...state,
                changedOther: action.changed
            };
        case types.CONTACT_RESET_FORM:
            return {
                ...state,
                lastname: '',
                firstname: '',
                surname: '',
                birthday: '',
                bornplace: '',
                liveplace: '',
                work: '',
                phone: '',
                serve: '',
                other: '',
                errorsLastname: [],
                errorsFirstname: [],
                errorsSurname: [],
                errorsBirthday: [],
                errorsBornplace: [],
                errorsLiveplace: [],
                errorsWork: [],
                errorsPhone: [],
                errorsServe: [],
                errorsOther: [],
                changedLastname: false,
                changedFirstname: false,
                changedSurname: false,
                changedBirthday: false,
                changedBornplace: false,
                changedLiveplace: false,
                changedWork: false,
                changedPhone: false,
                changedServe: false,
                changedOther: false,
                success_message: '',
                error_messages: [],
            };
        default:
            return state;
    }
}
