import * as types from "../types/footerType";

const initialState = {
    email: '',
    errorsEmail: [],
    changedEmail: false,

    success_message: '',
    error_messages: [],
};


export const footerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FOOTER_ERROR:
            return {
                ...state,
                success_message: '',
                error_messages: action.errors
            };
        case types.FOOTER_SUCCESS:
            return {
                ...state,
                success_message: action.message,
                error_messages: []
            };

        case types.FOOTER_CHANGE_EMAIL:
            return {
                ...state,
                success_message: '',
                email: action.email
            };
        case types.FOOTER_SET_EMAIL_ERROR:
            return {
                ...state,
                errorsEmail: action.errors
            };
        case types.FOOTER_CHANGED_EMAIL_STATE:
            return {
                ...state,
                changedEmail: action.changed
            };
        case types.FOOTER_RESET_FORM:
            return {
                ...state,
                email: '',
                errorsEmail: [],
                changedEmail: false,
                success_message: '',
                error_messages: [],
            };
        default:
            return state;
    }
}
